<template>
   <section class="cont dashboard-cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>仪表盘</el-breadcrumb-item>
            <el-breadcrumb-item>仪表盘</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="dashboard-box">
         <el-row class="section-box" >
            <el-row class="search-item">
               <label>平台绑定：</label>
               <el-select v-model="source" @change="changePlatform"  class="width-160" placeholder="选择平台">
                  <el-option v-for="item in platforms"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
               </el-select>
            </el-row>
            <el-row class="card-list" >
               <el-row class="card-bg">
                  <el-row class="card-item" style="background-image: linear-gradient(90deg, #006FFF, #189AFF);">
                     <el-row>
                        <img src="@/assets/dashboard-icon1.png" alt="">
                        <span></span>
                     </el-row>
                     <el-row class="card-item-font">
                        <p>总客户量</p>
                        <p>{{fansCount}}</p>
                     </el-row>
                  </el-row>
               </el-row>
               <el-row class="card-bg">
                  <el-row class="card-item" style="background-image: linear-gradient(90deg, #4ED3B5, #29E7BC);">
                     <el-row><img src="@/assets/dashboard-icon2.png" alt=""></el-row>
                     <el-row class="card-item-font">
                        <p>区域分布</p>
                        <p>8%</p>
                     </el-row>
                  </el-row>
               </el-row>
               <el-row class="card-bg">
                  <el-row class="card-item" style="background-image:linear-gradient(90deg, #F56C6C, #F47F40);">
                     <el-row><img src="@/assets/dashboard-icon3.png" alt=""></el-row>
                     <el-row class="card-item-font">
                        <p>今日新增</p>
                        <p>{{todayCount}}</p>
                     </el-row>
                  </el-row>
               </el-row>
               <el-row class="card-bg">
                  <el-row class="card-item" style="background-image: linear-gradient(90deg, #FFA132, #FFD132);">
                     <el-row><img src="@/assets/dashboard-icon4.png" alt=""></el-row>
                     <el-row class="card-item-font">
                        <p>转化率</p>
                        <p>{{conversionRate}}%</p>
                     </el-row>
                  </el-row>
               </el-row>
            </el-row>
         </el-row>
         <el-row class="section-box">
            <el-row class="title-item">
               <p class="title">酒店客户复购率（单日）</p>
            </el-row>
            <el-row class="search-item">
               <label>昨日，次日留存：</label>
               <el-date-picker
                     v-model="statisticsDay"
                     align="left"
                     type="date"
                     @change="getDayRePurchaseRate"
                     placeholder="选择日期"
                     value-format="yyyy-MM-dd"
                     :picker-options="dayPickerOptions">
               </el-date-picker>
            </el-row>
            <el-table ref="mainTable" border fit :data="dayReData"  :stripe="true" class="mainstay"
                      :tree-props="{children: 'children', hasChildren: 'hasChildren'}" >
               <el-table-column prop="statisticsDay" label="日期"></el-table-column>
               <el-table-column prop="totalConsumptionCount" label="当日购买量"></el-table-column>
               <el-table-column prop="reConsumptionCount" label="当日复购量"></el-table-column>
            </el-table>
         </el-row>
         <el-row class="section-box">
            <el-row class="title-item">
               <p class="title">酒店客户复购率（月度）</p>
            </el-row>
            <el-row class="search-item">
               <label>过去30日，次日留存：</label>
               <el-date-picker
                     v-model="statisticsMonth"
                     align="left"
                     type="date"
                     @change="getMonthRePurchaseRate"
                     placeholder="选择日期"
                     value-format="yyyy-MM-dd"
                     :picker-options="dayPickerOptions">
               </el-date-picker>
            </el-row>
            <el-table ref="mainTable" border fit :data="monthReData" class="mainstay"
                      max-height="300" :stripe="true" >
               <el-table-column prop="statisticsDay" label="日期"></el-table-column>
               <el-table-column prop="totalConsumptionCount" label="月购买量"></el-table-column>
               <el-table-column prop="reConsumptionCount" label="月复购量"></el-table-column>
            </el-table>
         </el-row>
         <el-row class="user-num">
            <el-col class="user-items" style="margin-right: 30px;">
               <el-row class="section-box">
                  <el-row class="title-item">
                     <p class="title">活跃老客户数 </p>
                  </el-row>
                  <el-row class="search-item">
                     <label>过去7日，按日，所有用户：</label>
                     <el-date-picker
                           v-model="statisticsDay2"
                           @change="getWeekActiveUser"
                           align="left"
                           type="date"
                           placeholder="选择日期"
                           value-format="yyyy-MM-dd"
                           :picker-options="dayPickerOptions">
                     </el-date-picker>
                  </el-row>
                  <el-table :data="activeData" border fit style="width: 80%" height="220" class="mainstay">
                     <el-table-column prop="statisticsDay" label="日期"></el-table-column>
                     <el-table-column prop="totalConsumptionCount" label="活跃人数"></el-table-column>
                  </el-table>
               </el-row>
            </el-col>
            <el-col  class="user-items">
               <el-row class="section-box">
                  <el-row class="title-item">
                     <p class="title">沉睡客户唤醒数 </p>
                  </el-row>
                  <el-row class="search-item">
                     <label>过去30日，次日留存：</label>
                     <el-date-picker
                           @change="getWeekSleepUser"
                           v-model="statisticsDay3"
                           align="right"
                           type="date"
                           placeholder="选择日期"
                           value-format="yyyy-MM-dd"
                           :picker-options="dayPickerOptions">
                     </el-date-picker>
                  </el-row>
                  <el-table :data="sleepData" border fit style="width: 96%;" height="220" >
                     <el-table-column prop="statisticsDay" label="日期"></el-table-column>
                     <el-table-column prop="todayCount" label="唤醒人数"></el-table-column>
                  </el-table>
               </el-row>
            </el-col>
         </el-row>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { dashboard } from '@/api/interface/smtech'
   import { mapState } from 'vuex'
   export default {
      computed: {
         ...mapState(['hotelInfo'])
      },
      data() {
         return{
            platforms: [
              { label: '微信公众号', value: 'WECHAT_OFFICIAL' },
              { label: '微信小程序', value: 'WECHAT_APPLET' },
               // { label: '自助机', value: 'SSM' },
              { label: 'APP', value: 'APP' },
              { label: 'OTA', value: 'OTA' },
               // { label: 'PC端', value: 'WEB' },
               // { label: '平台', value: 'PLATFORM' },
               // { label: '短信', value: 'SMS' },
               // { label: '邮件', value: 'EMAIL' },
               // { label: 'QQ', value: 'QQ' },
               // { label: '支付宝', value: 'ALIPAY' },
               // { label: '钉钉', value: 'DINGTALK' },
               { label: '抖音', value: 'DOUYIN_PLATFORM' },
               // { label: '小红书', value: 'XIAOHONGSHU' },
               { label: '酒店PMS', value: 'PMS' },
            ],
            source: 'WECHAT_OFFICIAL',       // 绑定来源
            fansCount: '',       // 总量
            todayCount: '',      // 今日增量
            conversionRate: '',  // 转化率
            hotelId: '',
            dayReData: [],       // 日复购列表
            monthReData: [],     // 月复购列表
            dayPickerOptions: {
               disabledDate(time) {
                  return time.getTime() > Date.now();
               },
               shortcuts: [{
                  text: '今天',
                  onClick(picker) {
                     picker.$emit('pick', new Date());
                  }
               }, {
                  text: '昨天',
                  onClick(picker) {
                     const date = new Date();
                     date.setTime(date.getTime() - 3600 * 1000 * 24);
                     picker.$emit('pick', date);
                  }
               }, {
                  text: '一周前',
                  onClick(picker) {
                     const date = new Date();
                     date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                     picker.$emit('pick', date);
                  }
               }]
            },
            statisticsDay: '',   // 统计天
            statisticsMonth: '', // 统计月
            statisticsDay2: '',
            statisticsDay3: '',
            activeData: [],
            sleepData: []
         }
      },
     mounted() {
         this.hotelId = this.hotelInfo.id
        this.getStatisticsMemberRecords()
        this.getDayRePurchaseRate()
        this.getMonthRePurchaseRate()
        this.getWeekActiveUser()
        this.getWeekSleepUser()
     },
      methods: {
         // 改变平台来源
         changePlatform(){
            this.getStatisticsMemberRecords()
            this.getDayRePurchaseRate()
            this.getMonthRePurchaseRate()
            this.getWeekActiveUser()
            this.getWeekSleepUser()
         },
         // 获取会员今日增量和总量
         getStatisticsMemberRecords(){
            const url = dashboard.statisticsMemberRecords
            const param = {
               source: this.source,
               hotelId: this.hotelId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  let data = res.records
                  this.fansCount = data.fansCount
                  this.todayCount = data.todayCount
                  if(data.todayCount === 0 && data.fansCount === 0){
                     this.conversionRate = "0.00"
                  }else{
                     this.conversionRate = ((data.todayCount / data.fansCount) * 100).toFixed(2)
                  }
               }
            })
         },
         // 获取酒店客房复购率（单日）
         getDayRePurchaseRate(){
            const url = dashboard.rePurchaseRate
            const param = {
               hotelId: this.hotelId,
               source: this.source,
               statisticsDay: this.statisticsDay,
               date: 'DAY',
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.dayReData = res.records.marketingDTOList
               }
            })
         },
         // 获取酒店客房复购率(月度)
         getMonthRePurchaseRate(){
            const url = dashboard.rePurchaseRate
            const param = {
               hotelId: this.hotelId,
               source: this.source,
               statisticsDay: this.statisticsMonth,
               date: 'MONTH',
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.monthReData = res.records.marketingDTOList
               }
            })
         },
         // 获取周活跃老客户
         getWeekActiveUser(){
            const url = dashboard.weekActiveUser
            const param = {
               hotelId: this.hotelId,
               source: this.source,
               date: 'WEEK',
               statisticsDay: this.statisticsDay2
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.activeData = res.records.marketingDTOList
               }
            })
         },
         // 获取周沉睡客户唤醒数
         getWeekSleepUser(){
            const url = dashboard.weekSleepUser
            const param = {
               hotelId: this.hotelId,
               source: this.source,
               date: 'WEEK',
               statisticsDay: this.statisticsDay3
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.sleepData = res.records.marketingDTOList
               }
            })
         }
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.getStatisticsMemberRecords()
                  this.getDayRePurchaseRate()
                  this.getMonthRePurchaseRate()
                  this.getWeekActiveUser()
                  this.getWeekSleepUser()
               }
            },
            deep: true
         }
      },
   }
</script>

<style scoped lang="scss">
.dashboard-cont{
   /deep/ .el-table{
      .el-table__header th{
         background: #f4f4f4; color: #2D2D2D;
      }
   }
   .dashboard-box{
      padding: 40px 30px;
      background-color: white;
      border-radius: 10px;
   }
   .section-box{
      .title-item{
         font-family: Microsoft YaHei;
         font-size: 18px;
         font-weight: 400;
         color: #2D2D2D;
         margin-bottom: 22px;
      }
      .search-item{
         font-family: Microsoft YaHei;
         font-size: 18px;
         font-weight: 400;
         color: #606266;
         margin-bottom: 30px;
      }
      .mainstay{
         width: 92%;
         margin-bottom: 53px;
         margin-left:97px;
      }
   }
   .user-num{
      display: flex;
      .user-items{
         width: 49%;
      }
   }
   .card-list{
      display: flex;
      margin-bottom: 51px;
      margin-left:92px;
      .card-bg{
         color: #ffffff;
         margin-right: 60px;
         width: 20%;
         .card-item{
            width: 100%;
            padding: 11% 0% 11% 24%;
            display: flex;
            border-radius: 8px;
            div{
               img{
                  width: 40px;
                  height: 40px;
                  margin-right: 8px;
                  margin-top: 5px;
               }
            }
            .card-item-font{
               height: 52px;
               width: 92px;
               p:first-child{
                  font-size: 18px;
               }
               p:last-child{
                  font-size: 28px;
                  font-weight:bold;
               }
            }
         }
      }
      //.card-bg{
      //   width: 300px;
      //   height: 123px;
      //   color: #ffffff;
      //   .card-item{
      //      padding: 20px 0;
      //      border-radius: 8px;
      //      display: flex;
      //      justify-content: center;
      //      img { width: 80px; margin-right: 10px }
      //      .card-item-font{
      //         p:nth-child(2){
      //            font-size: 28px;
      //            font-weight: 400;
      //            margin: 4px 0 6px 0;
      //         }
      //         p:nth-child(3){
      //            font-size: 12px;
      //         }
      //         span{
      //            margin: 0 8px 0 0;
      //         }
      //      }
      //   }
      //}
      //.card-bg:nth-child(4){
      //   padding-right: 0;
      //}
   }
}
</style>
